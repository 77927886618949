<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Property Details</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Property Name</span>
            <v-text-field
              dense
              outlined
              type="text"
              v-model="propertyName"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Total Rooms</span>
            <v-text-field
              dense
              v-model="totalRooms"
              outlined
              type="number"
              hide-spin-buttons
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Category</span>
            <v-select
              hide-details="auto"
              v-model="category"
              :items="hotelCategory"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Type</span>
            <v-select
              hide-details="auto"
              v-model="hotelType"
              :items="hotelTypes"
              item-text="hotel_type_name"
              item-value="_id"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">No.Of Restaurants</span>
            <v-text-field
              dense
              v-model="restaurants"
              outlined
              type="number"
              hide-spin-buttons
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">No.Of Floors</span>
            <v-text-field
              dense
              v-model="noOfFloor"
              outlined
              type="number"
              hide-spin-buttons
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 pt-4>
            <span class="title2">Is GST present?</span>

            <v-radio-group
              dense
              v-model="gstAvailable"
              row
              class="mt-0 pt-0"
              hide-details="auto"
            >
              <v-radio label="Yes" :value="true"></v-radio>
              <v-radio label="No" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left v-if="gstAvailable === true">
            <span class="title2">GST Number</span>
            <v-text-field
              dense
              v-model="gstNumber"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <v-checkbox
              hide-details
              class="mt-0"
              label="24 Hrs check-in"
              v-model="timezone"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 sm10 v-if="timezone === false">
            <v-layout wrap>
              <v-flex xs6>
                <vue-timepicker
                  format="hh:mm A"
                  v-model="checkin"
                  placeholder="Check-in Time"
                ></vue-timepicker>
              </v-flex>
              <v-flex xs6>
                <vue-timepicker
                  format="hh:mm A"
                  v-model="checkout"
                  placeholder="Check-out Time"
                ></vue-timepicker>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm10 pt-4>
            <v-radio-group
              dense
              v-model="expiryType"
              row
              class="mt-0 pt-0"
              hide-details="auto"
            >
              <v-radio label="License Expiry" value="licenseExpiry"></v-radio>
              <v-radio
                label="Insurance Expiry"
                value="insuranceExpiry"
              ></v-radio>
            </v-radio-group>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="expiryDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="expiryDate"
                  background-color="white"
                  outlined
                  color="#182444"
                  readonly
                  hide-details
                  clearable
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="expiryDate"
                no-title
                :min="nowDate"
                scrollable
                @change="$refs.menu2.save(expiryDate)"
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="font3">Property License/Insurance</span
            ><span style="color: #f17343">*</span
            ><span
              style="
                font-family: LexendFont;
                font-size: 14px;
                font-weight: 300;
                color: grey;
              "
              >front & back side in single file</span
            >
            <v-file-input
              ref="fileInput2"
              label="File input"
              outlined
              dense
              hide-details="auto"
              v-model="boatLicenseProof"
              @change="getFiles"
            >
              <template v-slot:append>
                <v-btn
                  dense
                  class="hidden-xs-only btnstly"
                  small
                  @click="openFileInput2"
                  ><span style="color: white">Choose File</span></v-btn
                >
              </template>
            </v-file-input>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="font3">Owner/Lease Document </span
            ><span style="color: #f17343">*</span
            ><span
              style="
                font-family: LexendFont;
                font-size: 14px;
                font-weight: 300;
                color: grey;
              "
              >(front & back side in single file)</span
            >
            <v-file-input
              ref="fileInput"
              label="File input"
              outlined
              dense
              hide-details="auto"
              v-model="leaseProof"
              @change="getFiles2"
            >
              <template v-slot:append>
                <v-btn
                  dense
                  class="hidden-xs-only btnstly"
                  small
                  @click="openFileInput"
                  ><span style="color: white">Choose File</span></v-btn
                >
              </template>
            </v-file-input>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Whatsapp Number</span>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  dense
                  v-model="whatsappNumber"
                  outlined
                  type="number"
                  hide-spin-buttons
                  :rules="phoneRules"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">OTP Mail Id</span>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  dense
                  v-model="otpMail"
                  outlined
                  type="text"
                  hide-spin-buttons
                  :rules="[rules.required]"
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Contact</span>
            <v-layout wrap>
              <v-flex xs11>
                <v-text-field
                  dense
                  v-model="newContact"
                  outlined
                  :rules="phoneRules"
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                />
              </v-flex>
              <v-flex xs1 align-self-center text-center>
                <v-icon @click="addContactField" :disabled="!newContact">
                  mdi-plus
                </v-icon>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="contacts.length > 0">
              <v-flex
                xs12
                pt-4
                v-for="(contact, index) in contacts"
                :key="index"
              >
                <v-layout wrap>
                  <v-flex xs11>
                    <v-text-field
                      dense
                      hide-details
                      v-model="contacts[index]"
                      outlined
                      type="number"
                      hide-spin-buttons
                      disabled
                    />
                  </v-flex>
                  <v-flex xs1 align-self-center text-center>
                    <v-icon @click="deleteContact(index)"> mdi-delete </v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Mail ID</span>
            <v-layout wrap>
              <v-flex xs11>
                <v-text-field
                  dense
                  v-model="newMail"
                  outlined
                  type="email"
                  hide-details="auto"
                  :rules="[rules.email]"
                />
              </v-flex>
              <v-flex xs1 align-self-center>
                <v-icon @click="addMailField" :disabled="!newMail">
                  mdi-plus
                </v-icon>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="mails.length > 0">
              <v-flex xs12 pt-4 v-for="(mail, index) in mails" :key="index">
                <v-layout wrap>
                  <v-flex xs11>
                    <v-text-field
                      dense
                      hide-details
                      v-model="mails[index]"
                      outlined
                      type="email"
                      hide-spin-buttons
                      disabled
                    />
                  </v-flex>
                  <v-flex xs1 align-self-center>
                    <v-icon @click="deleteMail(index)"> mdi-delete </v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Property Image Upload</span>
            <v-layout wrap pt-4>
              <v-flex xs3 pb-2 text-center>
                <v-card
                  tile
                  elevation="0"
                  color="#e8e8e8"
                  class="mr-4"
                  height="80px"
                  width="90px"
                >
                  <ImageComp
                    :singleImage="coverImage"
                    @stepper="winStepper"
                    :width="700"
                    :height="500"
                    :componentType="'coverImage'"
                  />
                </v-card>
                <span class="title3" style="color: #505050">Cover Image</span>
              </v-flex>
            </v-layout>
            <!-----------------------ADDITIONAL IMG--------------------------->
            <v-layout wrap pt-2>
              <v-flex xs12 sm3 pb-2 text-left>
                <v-card
                  tile
                  @click="$refs.files.click()"
                  elevation="0"
                  color="#ED7243"
                  class="mr-4"
                  height="80px"
                  width="90px"
                >
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="files"
                    type="file"
                    @change="uploadImages"
                  />
                  <v-layout wrap fill-height>
                    <v-flex xs12 align-self-center text-center>
                      <v-icon color="white">mdi-plus-circle-outline</v-icon>
                    </v-flex>
                  </v-layout>
                </v-card>
                <span class="title3" style="color: #505050"
                  >Additional Images</span
                >
              </v-flex>
              <v-flex xs12 sm3 v-for="(item, i) in imageArray" :key="i">
                <v-img :src="item" height="80px" width="90px" contain>
                  <v-layout wrap>
                    <v-flex text-right pa-0>
                      <v-avatar color="#FF3434" size="15">
                        <v-icon color="#FFF" small @click="removeImageArray(i)">
                          mdi-close
                        </v-icon>
                      </v-avatar>
                    </v-flex>
                  </v-layout>
                </v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 pt-4 text-right align-self-baseline>
            <v-btn @click="validation()" class="btnstly" style="cursor: pointer"
              ><span style="font-family: LexendFont; text-transform: none"
                >Save</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
        <v-dialog
          v-model="cropImageDialog"
          persistent
          :width="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? '100vw'
              : $vuetify.breakpoint.name == 'md'
              ? '80vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '50vw'
              : '40vw'
          "
        >
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12>
                <ImageCropper
                  :image="currentImage"
                  :key="currentImage"
                  :width="700"
                  :height="700"
                  @stepper="imageCropper"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="cropImageDialog2"
          persistent
          :width="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? '100vw'
              : $vuetify.breakpoint.name == 'md'
              ? '80vw'
              : $vuetify.breakpoint.name == 'lg'
              ? '50vw'
              : '40vw'
          "
        >
          <v-card>
            <v-layout wrap justify-center pa-2>
              <v-flex xs12>
                <ImageCropper2
                  :image="currentImage2"
                  :key="currentImage2"
                  :width="700"
                  :height="700"
                  @stepper="imageCropper2"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import moment from "moment";
import ImageComp from "./singleImages";
import ImageCropper from "./imageCropper";
import ImageCropper2 from "./../../common/imageCropper2";
export default {
  components: {
    "vue-timepicker": VueTimepicker,
    ImageComp,
    ImageCropper,
    ImageCropper2,
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      data: [],
      msg: null,
      page: 1,
      limit: 20,
      nowDate: new Date().toISOString().slice(0, 10),
      newContact: "",
      contacts: [],
      newMail: "",
      mails: [],
      propertyName: "",
      timezone: false,
      totalRooms: "",
      category: "",
      restaurants: "",
      gstNumber: "",
      gstAvailable: false,
      noOfFloor: "",
      checkin: "",
      checkout: "",
      hotelType: "",
      hotelTypes: [],
      expiryType: "",
      expiryDate: "",
      whatsappNumber: "",
      otpMail: "",
      hotel_id: "",
      hotelCategory: [
        "Hotels",
        "Resorts",
        "Guest Houses",
        "Homestays",
        "Hostels",
        "Boutique Hotels",
        "Heritage Hotels",
        "Apartment",
        "Villas",
        "Cottages",
        "Bungalows",
        "Farm Stays",
        "Dormitories",
        "Lodge",
        "Downtown Hotel",
        "Sub-urban Hotel",
        "Airport Hotel",
        "Motel",
        "Flotel",
        "Rotel",
        "Commercial or Business Hotels",
        "Transient Hotels",
        "Residential Hotels",
        "Casino Hotels",
        "Conference Centers",
        "Green Hotel",
      ],
      //image upload
      imageId: "",
      fullImage: "",
      upperImage: "",
      interiorImage: "",
      coverImage: "",
      // roomImage: "",
      boatLicenseProof: "",
      attachments: null,
      leaseProof: "",
      attachments2: null,
      formData: new FormData(),
      imageArray: [],
      imageFiles: [],
      selectedFiles: null,
      currentImage: "",
      cropImageDialog: false,
      //
      imageArray2: [],
      currentImage2: "",
      cropImageDialog2: false,
      ImgselectedFiles2: "",
      roomImage: [],
      //rules
      menu2: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      pinRules: [
        (v) => !!v || "PIN is required",
        (v) => (v && v.length === 6) || "PIN must be 6 digits",
      ],
      phoneRules: [
        (v) => !v || v.length <= 10 || "number must be less than 11 characters",
        (v) =>
          !v || v.length >= 10 || "number must be greater than 10 characters",
        (v) => !v || /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },

  mounted() {
    this.getcategoryList();
  },
  methods: {
    addContactField() {
      // Add the new contact to the array if it's not empty
      if (this.newContact) {
        this.contacts.push(this.newContact);
        this.newContact = ""; // Reset the new contact field
      }
    },
    deleteContact(index) {
      this.contacts.splice(index, 1); // Remove the contact at the given index
    },
    addMailField() {
      // Add the new mail to the array if it's not empty
      if (this.newMail) {
        this.mails.push(this.newMail);
        this.newMail = ""; // Reset the new mail field
      }
    },
    deleteMail(index) {
      this.mails.splice(index, 1); // Remove the mail at the given index
    },
    openFileInput() {
      // Trigger a click event on the file input element
      this.$refs.fileInput.$el.querySelector("input").click();
    },
    openFileInput2() {
      // Trigger a click event on the file input element
      this.$refs.fileInput2.$el.querySelector("input").click();
    },
    winStepper(window_data) {
      if (window_data.type == "fullImage") {
        this.fullImage = window_data.selectedFiles;
        console.log("fullImage is", this.fullImage);
      }
      if (window_data.type == "coverImage") {
        this.coverImage = window_data.selectedFiles;
        console.log("coverImage is", this.coverImage);
      }
      if (window_data.type == "upperImage") {
        this.upperImage = window_data.selectedFiles;
        console.log("upperImage is", this.upperImage);
      }
      if (window_data.type == "interiorImage") {
        this.interiorImage = window_data.selectedFiles;
        console.log("interiorImage is", this.interiorImage);
      }
      // if (window_data.type == "roomImage") {
      //   this.roomImage = window_data.selectedFiles;
      //   console.log("roomImage is", this.roomImage);
      // }
      // if (window_data.type == "roomImage") {
      //   this.imageArray2 = window_data.imageArray;
      //   this.imageFiles2.push(window_data.selectedFiles);
      // }
      // if (window_data.type == "image") {
      //   this.imageArray = window_data.imageArray;
      //   this.imageFiles.push(window_data.selectedFiles);
      // } else if (window_data.type == "imageRemoval") {
      //   var values = this.formData.getAll("photos");
      //   values.splice(window_data.removalItem, 1);
      //   this.formData.set("photos", values);
      //   this.imageArray = window_data.imageArray;
      // }
    },
    //rooms images
    uploadImages2(event) {
      if (this.imageArray2.length < 4) {
        this.currentImage2 = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog2 = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    removeImageArray2(i) {
      var values = this.formData.getAll("roomImage");
      values.splice(i, 1);

      // Remove the existing "roomImage" key from formData
      this.formData.delete("roomImage");

      // Re-append the modified array
      values.forEach((value, index) => {
        this.formData.append("roomImage", value, `roomImage${index + 1}`);
      });

      this.imageArray2.splice(i, 1);
    },
    imageCropper2(payload) {
      this.cropImageDialog2 = payload.dialog;
      if (payload.image) {
        this.collectImages2(payload.image);
      }
    },
    collectImages2(image) {
      this.ImgselectedFiles2 = image;
      // this.Images.append("image", this.ImgselectedFiles2);
      this.formData.append("roomImage", this.ImgselectedFiles2);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray2.push(urls);
        if (this.imageArray2) {
          this.roomImage = this.imageArray2[0];
        }
      };
      console.log("ImgselectedFiles==", this.ImgselectedFiles2);
    },
    //additional images
    uploadImages(event) {
      if (this.imageArray.length < 10) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    removeImageArray(i) {
      var values = this.formData.getAll("additionalImages");
      values.splice(i, 1);

      // Remove the existing "additionalImages" key from formData
      this.formData.delete("additionalImages");

      // Re-append the modified array
      values.forEach((value, index) => {
        this.formData.append("additionalImages", value, `image${index + 1}`);
      });

      this.imageArray.splice(i, 1);
    },
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    collectImages(image) {
      this.ImgselectedFiles = image;
      this.formData.append("additionalImages", this.ImgselectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
      console.log("ImgselectedFiles==", this.ImgselectedFiles);
    },
    getFiles() {
      var selectedFiles = this.boatLicenseProof;
      console.log("img=", this.boatLicenseProof);
      if (this.boatLicenseProof) {
        this.msg = null;
        this.attachments = selectedFiles;
        console.log("attachments=", this.attachments);
      }
    },
    getFiles2() {
      // this.categoryImg = this.Image
      var selectedFiles2 = this.leaseProof;
      console.log("img=", this.leaseProof);
      if (this.leaseProof) {
        this.msg = null;
        this.attachments2 = selectedFiles2;
        console.log("attachments2=", this.attachments2);
      }
    },
    ImageUpload(id) {
      if (this.imageArray.length > 0) {
        for (var i = 0; i < this.imageFiles.length; i++)
          this.formData.append("additionalImages", this.imageFiles[i]);
      }
      this.formData.append("id", id);
      this.formData.append("coverImage", this.coverImage);

      this.formData.append("propertyLiscence", this.attachments);
      this.formData.append("leaseAgreementOrOwership", this.attachments2);
      console.log("formData", this.formData);
      this.appLoading = true;
      axios({
        method: "post",
        url: "/hotel/upload/images",
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
        data: this.formData,
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.formData = new FormData();
          this.coverImage = "";
          this.attachments = "";
          this.attachments2 = "";
          this.imageFiles = [];
          this.imageArray = [];
          this.msg = response.data.msg;
          this.showSnackBar = true;

          this.$emit("id-received", this.hotel_id);
        } else {
          // alert("failed to update cover image");
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
    },
    validation() {
      if (!this.propertyName) {
        this.msg = "Please enter name";
        this.showSnackBar = true;
        return;
      } else if (!this.totalRooms) {
        this.msg = "Please enter total number of rooms";
        this.showSnackBar = true;
        return;
      } else if (!this.totalRooms) {
        this.msg = "Please enter total rooms";
        this.showSnackBar = true;
        return;
      } else if (!this.category) {
        this.msg = "Please choose category";
        this.showSnackBar = true;
        return;
      } else if (!this.hotelType) {
        this.msg = "Please choose hotel type";
        this.showSnackBar = true;
        return;
      }
      //  else if (!this.noOfFloor) {
      //   this.msg = "Please enter no.of floors";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.restaurants) {
      //   this.msg = "Please enter no.of restaurants";
      //   this.showSnackBar = true;
      //   return;
      // }
      else if (!this.whatsappNumber) {
        this.msg = "Please enter Whatsapp Number";
        this.showSnackBar = true;
        return;
      } else if (!this.otpMail) {
        this.msg = "Please enter OTP mail Id";
        this.showSnackBar = true;
        return;
      }
      // else if (!this.timezone) {
      //   this.msg = "Please choose is 24hrs chekin or not";
      //   this.showSnackBar = true;
      //   return;
      // }
      else if (!this.expiryType) {
        this.msg = "Please choose expiry type";
        this.showSnackBar = true;
        return;
      } else if (!this.expiryDate) {
        this.msg = "Please enter expiry date";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments) {
        this.msg = "Please upload licence proof";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments2) {
        this.msg = "Please upload lease proof";
        this.showSnackBar = true;
        return;
      } else if (!this.coverImage) {
        this.msg = "Please upload cover image";
        this.showSnackBar = true;
        return;
      } else if (this.imageArray.length < 1) {
        this.msg = "Please upload atleast one additional image";
        this.showSnackBar = true;
        return;
      } else {
        this.addHotel();
      }
    },
    addHotel() {
      console.log("11111111111");
      this.appLoading = true;
      let checkout24hr;
      let checkin24hr;
      if (this.timezone === true) {
        console.log("2222222");
        checkin24hr = "";
        checkout24hr = "";
      } else {
        console.log("33333333333");
        checkin24hr = moment(this.checkin, "hh:mm A").format("HH:mm");
        checkout24hr = moment(this.checkout, "hh:mm A").format("HH:mm");
      }
      axios({
        method: "POST",
        url: "/hotel/add",
        data: {
          name: this.propertyName,
          totalRooms: this.totalRooms,
          gstAvailable: this.gstAvailable,
          gstNumber: this.gstNumber,
          category: this.category,
          hotelType: this.hotelType,
          totalFloors: this.noOfFloor,
          numberOfRestaurants: this.restaurants,
          checkinTime: checkin24hr,
          checkOutTime: checkout24hr,
          is24Hrs: this.timezone,
          expiryType: this.expiryType,
          liscenceExpiry: this.expiryDate,
          whatsappNumber: this.whatsappNumber,
          mailId: this.otpMail,
          contact: this.contacts,
          email: this.mails,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.imageId = response.data.hotel_details._id;
          console.log("id=", this.imageId);
          this.hotel_id = response.data.hotel_details._id;
          // this.$emit('id-received', this.hotel_id);
          if (this.imageId) {
            console.log("sdfghj");
            this.ImageUpload(this.imageId);
          } else {
            this.$router.push("/Houseboats");
          }
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
    },

    getcategoryList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hotel/type/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.hotelTypes = response.data.data;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>