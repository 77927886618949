var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#182444","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#ff6200","right":"","top":"","timeout":"2000"},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pt-2":""}},[_c('v-flex',{attrs:{"xs12":"","sm3":"","align-self-center":"","pl-8":""}},[_c('span',{staticClass:"title1",style:({
          'font-size':
            _vm.$vuetify.breakpoint.name == 'xs'
              ? '20px'
              : _vm.$vuetify.breakpoint.name == 'sm'
              ? '20px'
              : _vm.$vuetify.breakpoint.name == 'md'
              ? '25px'
              : _vm.$vuetify.breakpoint.name == 'lg'
              ? '25px'
              : '30px',
        })},[_vm._v("Add GST")])])],1),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pa-4":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":""}},[_c('v-layout',{attrs:{"wrap":"","justify-start":"","px-4":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('span',{staticClass:"title2"},[_vm._v("Is GST present?")]),_c('v-radio-group',{staticClass:"mt-0 pt-0",attrs:{"dense":"","row":"","hide-details":"auto"},model:{value:(_vm.gstAvailable),callback:function ($$v) {_vm.gstAvailable=$$v},expression:"gstAvailable"}},[_c('v-radio',{attrs:{"label":"Yes","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1)],1),(_vm.gstAvailable===true)?_c('v-flex',{attrs:{"xs12":"","sm6":"","pt-4":"","pt-sm-0":"","text-left":""}},[_c('span',{staticClass:"title2"},[_vm._v("GST Number")]),_c('v-text-field',{attrs:{"dense":"","outlined":"","type":"text","hide-details":"auto"},model:{value:(_vm.gstNumber),callback:function ($$v) {_vm.gstNumber=$$v},expression:"gstNumber"}})],1):_vm._e()],1),_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"headline text-center"},[_vm._v("GST Rates")]),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.gstRates),function(rate){return _c('v-flex',{key:rate.value,staticClass:"mx-2",attrs:{"xs2":""}},[_c('v-card',{staticClass:"d-flex justify-center align-center",class:{
                  'selected-card': _vm.selectedGSTRate === rate.value,
                  'unselected-card': _vm.selectedGSTRate !== rate.value
                },staticStyle:{"cursor":"pointer"},attrs:{"width":"70px","height":"60px"},on:{"click":function($event){return _vm.selectGSTRate(rate.value)}}},[_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"height":"100%"}},[_c('span',[_vm._v(_vm._s(rate.label))])])])],1)}),1)],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","pt-4":"","text-left":"","px-4":""}},[_c('span',{staticClass:"title2"},[_vm._v("Supply type(State)")]),_c('v-select',{attrs:{"items":_vm.states,"label":"Select State","dense":"","outlined":"","hide-details":"auto","autocomplete":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","px-0":"","px-sm-4":"","pt-4":"","text-left":""}},[_c('span',{staticClass:"title2"},[_vm._v("Country")]),_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":"","type":"text","hide-details":"auto"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md4":"","pt-4":"","text-left":"","px-4":""}},[_c('v-btn',{staticClass:"btnstly",staticStyle:{"cursor":"pointer"},attrs:{"block":""},on:{"click":function($event){return _vm.addTax()}}},[_c('span',{staticStyle:{"font-family":"LexendFont","text-transform":"none"}},[_vm._v("Save")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }