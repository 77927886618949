<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add GST</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4>
      <v-flex xs12 sm8>
        <v-layout wrap justify-start px-4>
          <v-flex xs12 sm6>
            <span class="title2">Is GST present?</span>

            <v-radio-group
              dense
              v-model="gstAvailable"
              row
              class="mt-0 pt-0"
              hide-details="auto"
            >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 sm6 pt-4 pt-sm-0 text-left v-if="gstAvailable===true">
            <span class="title2">GST Number</span>
            <v-text-field
              dense
              v-model="gstNumber"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
      
        </v-layout>
        <v-layout wrap justify-start>
      <v-flex xs12>
        <v-card elevation="0" >
          <v-card-title class="headline text-center">GST Rates</v-card-title>
          <v-card-text>
            <!-- Flex Layout for Selecting GST Rates -->
            <v-layout row wrap>
              <v-flex
                v-for="rate in gstRates"
                :key="rate.value"
                xs2
                class="mx-2"
              >
                <v-card
                  width="70px"
                  height="60px"
                  :class="{
                    'selected-card': selectedGSTRate === rate.value,
                    'unselected-card': selectedGSTRate !== rate.value
                  }"
                  @click="selectGSTRate(rate.value)"
                  class="d-flex justify-center align-center"
                  style="cursor: pointer"
                >
                  <div class="d-flex justify-center align-center" style="height: 100%">
                    <span>{{ rate.label }}</span>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 pt-4  text-left px-4>
            <span class="title2">Supply type(State)</span>
            <v-select
    v-model="state"
    :items="states"
    label="Select State"
    dense
    outlined
    hide-details="auto"
    autocomplete
  >
  </v-select>
          </v-flex>
          <v-flex xs12 sm6 px-0 px-sm-4 pt-4  text-left >
            <span class="title2">Country</span>
            <v-text-field
              dense
              v-model="country"
              outlined disabled
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4 pt-4 text-left px-4>
            <v-btn block class="btnstly" style="cursor: pointer" @click="addTax()"
              ><span style="font-family: LexendFont; text-transform: none"
                >Save</span
              ></v-btn
            >
          </v-flex>
    </v-layout>
      </v-flex>
    </v-layout>
   
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ['hotelId'],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      gstAvailable:false,
      gstNumber:"",
      selectedGSTRate: null, // Stores the selected GST rate value
      gstRates: [
        { label: '5%', value: 5 },
        { label: '12%', value: 12 },
        { label: '18%', value: 18 },
        { label: '28%', value: 28 },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      country:"India",
      state:"",
      states: [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Andaman and Nicobar Islands',
        'Chandigarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Lakshadweep',
        'Delhi',
        'Puducherry',
        'Ladakh',
        'Jammu and Kashmir',
      ],
    };
  },

  methods: {
    selectGSTRate(value) {
      this.selectedGSTRate = value; // Set the selected GST rate
    },
    validation() {
      if (!this.location) {
        this.msg = "Please enter location name";
        this.showSnackBar = true;
        return;
      } else if (!this.mainTownName) {
        this.msg = "Please enter main town name";
        this.showSnackBar = true;
        return;
      } else if (!this.distanceFromTown) {
        this.msg = "Please enter distance from main town";
        this.showSnackBar = true;
        return;
      } else if (!this.link) {
        this.msg = "Please enter location link";
        this.showSnackBar = true;
        return;
      }else if (!this.address) {
        this.msg = "Please enter address";
        this.showSnackBar = true;
        return;
      } 
      else {
        this.add();
      }
    },
    addTax() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hotel/add/tax",
        data: {
          gstAvailable: this.gstAvailable,
          gstNumber: this.gstNumber,
          country: this.country,
          state: this.state,
          hotelId: this.hotelId
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$router.push("/hotel");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}

/* Styling for the selected GST card */
.selected-card {
  border: 2px solid #1976d2; /* Highlighting selected card with a border */
  background-color: #e3f2fd; /* Light blue background for selected */
}

/* Styling for the unselected GST card */
.unselected-card {
  border: 1px solid #e0e0e0; /* Regular border for unselected */
  background-color: #f5f5f5; /* Light grey background for unselected */
}
</style>