<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start pt-2 px-4>
      <v-flex xs12 sm3 align-self-center>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add Bank Details</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pa-4>
      <v-flex xs12>
        <v-card elevation="0">
          <v-layout wrap>
            <!-- Bank Details -->
            <v-flex xs12 sm6 align-self-start pr-0 pr-md-1>
              <v-layout wrap justify-center>
                <v-flex xs12 sm10>
                  <span class="title2">Bank Name</span>
                  <v-text-field
                    dense
                    v-model="bankDetails.bankName"
                    outlined
                    hide-details="auto"
                  />
                </v-flex>
                <v-flex xs12 sm10 pt-4>
                  <span class="title2">Branch Name</span>
                  <v-text-field
                    dense
                    v-model="bankDetails.branchName"
                    outlined
                    hide-details="auto"
                  />
                </v-flex>
                <v-flex xs12 sm10 pt-4>
                  <span class="title2">IFSC Code</span>
                  <v-text-field
                    dense
                    v-model="bankDetails.ifscCode"
                    outlined
                    hide-details="auto"
                  />
                </v-flex>
                <v-flex xs12 sm10 pt-4>
                  <span class="title2">Account Name</span>
                  <v-text-field
                    dense
                    v-model="bankDetails.accountName"
                    outlined
                    hide-details="auto"
                  />
                </v-flex>
                <v-flex xs12 sm10 pt-4>
                  <span class="title2">Account Number</span>
                  <v-text-field
                    dense
                    v-model="bankDetails.accountNumber"
                    outlined
                    hide-details="auto"
                    type="number"
                  />
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- Policy Details -->
            <v-flex xs12 sm6 align-self-start pl-0 pl-md-1>
              <v-layout wrap justify-center>
                <v-flex xs12 sm10>
                  <span class="title2">Booking Policy</span>
                  <v-textarea
                    dense
                    v-model="bankDetails.bookingPolicy"
                    outlined
                    hide-details="auto"
                    rows="4"
                    placeholder="Enter booking policy"
                  />
                </v-flex>
                <v-flex xs12 sm10 pt-4>
                  <span class="title2">Cancellation Policy</span>
                  <v-textarea
                    dense
                    v-model="bankDetails.cancellationPolicy"
                    outlined
                    hide-details="auto"
                    rows="4"
                    placeholder="Enter cancellation policy"
                  />
                </v-flex>
                <v-flex xs12 sm10 pt-4>
                  <v-btn
                    @click="add1()"
                    block
                    class="btnstly"
                    style="cursor: pointer"
                    ><span style="font-family: LexendFont; text-transform: none"
                      >Save</span
                    ></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["hotelId"],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      bankDetails: {
        bankName: "",
        branchName: "",
        ifscCode: "",
        accountName: "",
        accountNumber: "",
        bookingPolicy: "",
        cancellationPolicy: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },

  methods: {
    selectGSTRate(value) {
      this.selectedGSTRate = value; // Set the selected GST rate
    },
    validation() {
      if (!this.location) {
        this.msg = "Please enter location name";
        this.showSnackBar = true;
        return;
      } else if (!this.mainTownName) {
        this.msg = "Please enter main town name";
        this.showSnackBar = true;
        return;
      } else if (!this.distanceFromTown) {
        this.msg = "Please enter distance from main town";
        this.showSnackBar = true;
        return;
      } else if (!this.link) {
        this.msg = "Please enter location link";
        this.showSnackBar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please enter address";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    add1() {
      console.log("ffffffffffff")
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hotel/add/bankDetails",
        data: {
          bankName: this.bankDetails.bankName,
          branchName: this.bankDetails.branchName,
          ifscCode: this.bankDetails.ifscCode,
          accountName: this.bankDetails.accountName,
          accountNumber: this.bankDetails.accountNumber,
          bookingPolicy: this.bankDetails.bookingPolicy,
          cancellationPolicy: this.bankDetails.cancellationPolicy,
          hotelId: this.hotelId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$emit("next-tab");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}

/* Styling for the selected GST card */
.selected-card {
  border: 2px solid #1976d2; /* Highlighting selected card with a border */
  background-color: #e3f2fd; /* Light blue background for selected */
}

/* Styling for the unselected GST card */
.unselected-card {
  border: 1px solid #e0e0e0; /* Regular border for unselected */
  background-color: #f5f5f5; /* Light grey background for unselected */
}
</style>