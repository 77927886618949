<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <!-- <v-layout wrap justify-start pt-2>
          <v-flex xs12 sm3 align-self-center>
            <span
              class="title1"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '25px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '25px'
                    : '30px',
              }"
              >Property Details</span
            >
          </v-flex>
        </v-layout> -->
        <v-layout wrap justify-space-around pt-4>
          <v-flex xs12>
            <v-tabs
              background-color="#002635"
              dark
              touchless
              grow
              ref="tabs"
              class="nsbold"
              style="font-size: 16px"
              v-model="activeTab"
            >
              <v-tab :disabled="activeTab !== 0">Overview</v-tab>
              <v-tab :disabled="activeTab !== 1">Location</v-tab>
              <v-tab :disabled="activeTab !== 2">Amenities</v-tab>
              <v-tab :disabled="activeTab !== 3">Policies</v-tab>
              <v-tab :disabled="activeTab !== 4">Bank Details</v-tab>
              <v-tab :disabled="activeTab !== 5">Gst</v-tab>
              <!-- <v-tab>Gst</v-tab> -->
            </v-tabs>
            <v-tabs-items v-model="activeTab" touchless>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <overiewPageVue @id-received="handleIdReceived" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddLocation :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddAmenities :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddPolicies :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddBankDetails :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item  :touch="false">
                <v-card outlined pa-2>
                  <AddHotelTax :hotelId="id" @next-tab="nextTab" />
                </v-card>
              </v-tab-item>
            </v-tabs-items> 
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import overiewPageVue from "./overiewPage.vue";
import AddLocation from "./AddLocation.vue";
import AddAmenities from "./AddAmenities.vue";
import AddPolicies from "./AddPolicies.vue";
import AddBankDetails from "./AddBankDetails.vue";
import AddHotelTax from "./AddHotelTax.vue";
// import axios from "axios";
export default {
  components: {
    overiewPageVue,
    AddLocation,
    AddAmenities,
    AddPolicies,
    AddBankDetails,
    AddHotelTax,
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      data: [],
      msg: null,
      page: 1,
      limit: 20,
      activeTab: 0,
      id: null,
      nowDate: new Date().toISOString().slice(0, 10),

      // tabs: ["Overview", "Rooms", "Location", "Details", "Amenities", "Policies"],
      form1Data: {
        name: "",
        contact: "",
      },
      form2Data: {
        name: "",
        contact: "",
      },
      savedTabs: [false, false, false, false, false, false],
    };
  },

  // mounted() {
  //   this.getData();
  // },
  mounted() {
    this.disableSwipe();
    if (window.innerWidth <= 600) {
      const tabs = document.querySelector(".v-tabs");
      if (tabs) {
        tabs.addEventListener(
          "touchmove",
          (e) => {
            e.preventDefault();
          },
          { passive: false }
        );
      }
    }
  },
  methods: {
    disableSwipe() {
      const tabs = this.$refs.tabs;
      if (tabs) {
        tabs.addEventListener("touchstart", this.preventTouch, {
          passive: false,
        });
        tabs.addEventListener("touchmove", this.preventTouch, {
          passive: false,
        });
      }
    },
    preventTouch(event) {
      event.preventDefault();
    },
    handleIdReceived(id) {
      this.id = id;
      this.nextTab();
    },
    nextTab() {
      if (this.activeTab < 5) {
        this.activeTab += 1; // Move to the next tab
      }
    },
  
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}

@media (max-width: 600px) {
  .v-tabs {
    touch-action: none;
  }
}
</style>